import React from "react";
import { useEffect, useState, useRef } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  NavLink,
  useNavigate,
} from "react-router-dom";
import { Modal, Accordion, Offcanvas, Button } from "react-bootstrap";
import Image from "../pages/image";

export default function TemperatureMonitoringAnswerModal(props) {
  const { show, handleClose } = props;

  return (
    <>
      <Modal show={show} onHide={handleClose} scrollable={true} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Temperature Monitoring System</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ minHeight: "75vh", maxHeight: "65vh", overflowY: "auto" }}
        >
          <div className="container">
            <div className="mb-3">
              <span className="fs-1">UX Design Thinking </span>
            </div>
            <div className="mb-4">
              <div>
                <span className="fs-3">Solution Process (The What Part) </span>
              </div>
              <div>
                <span className="text-secondary">
                  The next stage of the solution process of Design Thinking
                  typically involves the identifying core requirements and
                  finalizing the achievable targets. This can be done through
                  brainstorming sessions and sorting out few solutions out of
                  many.
                </span>
              </div>
            </div>
            <div className="mb-4">
              <div>
                <span className="fs-3">Finalized Targets</span>
              </div>
              <div>
                <span className="text-secondary">
                  <ul>
                    <li>
                      <div className="mb-3">
                        <span className="text-secondary">
                          Real-time monitoring and control of temperature.
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="mb-3">
                        <span className="text-secondary">
                          Monitoring the temperature of up to three compartments
                          at a maximum.
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="mb-3">
                        <span className="text-secondary">
                          Tracking temperature history for a duration of one
                          month.
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="mb-3">
                        <span className="text-secondary">
                          Monitoring reefer alerts and their timeline.
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="mb-3">
                        <span className="text-secondary">
                          Graphical display of the open and closed states of
                          doors and their durations.
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="mb-3">
                        <span className="text-secondary">
                          Create and schedule reports for the reefer monitoring
                          system.
                        </span>
                      </div>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
            <div className="mb-4">
              <div>
                <span className="fs-3">
                  Basic Concept and Prototyping (The How Part)
                </span>
              </div>
              <div>
                <span className="text-secondary">
                  The process of "How" involves three phases: 1:
                  Conceptualization, 2: Sketching, and 3: Creating an actual
                  prototype.
                </span>
              </div>
            </div>
            <div className="mb-4 ">
              <div className="mb-2">
                <span className="fs-5">Temperature Chart Basic Concept</span>
              </div>
              <div
                className="d-flex"
                style={{ width: "100%", height: "500px" }}
              >
                <Image
                  src={"./images/temperature0.png"}
                  border={true}
                  zoom={true}
                ></Image>
              </div>
            </div>
            <div className="mb-4 ">
              <div className="mb-2">
                <span className="fs-5">Layout 1 Concept</span>
              </div>
              <div
                className="d-flex"
                style={{ width: "100%", height: "500px" }}
              >
                <Image
                  src={"./images/conceptfig2.jpg"}
                  border={true}
                  zoom={true}
                ></Image>
              </div>
            </div>
            <div className="mb-4 ">
              <div className="mb-2">
                <span className="fs-5">Layout 2 Concept</span>
              </div>
              <div
                className="d-flex"
                style={{ width: "100%", height: "500px" }}
              >
                <Image
                  src={"./images/conceptfig3.jpg"}
                  border={true}
                  zoom={true}
                ></Image>
              </div>
            </div>
            <div className="mb-4 ">
              <div className="mb-2">
                <span className="fs-5">Finalized Sketch</span>
              </div>
              <div
                className="d-flex"
                style={{ width: "100%", height: "500px" }}
              >
                <Image
                  src={"./images/conceptfig4.jpg"}
                  border={true}
                  zoom={true}
                ></Image>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
