import { v4 as uuid } from "uuid";

export default [
  {
    id: uuid(),
    name: "Burning Together",
    prefix: "Painting",
    title: "Burning Together",
    subtitle:
      "In India, there are many families who struggle to make ends meet and provide for their basic needs. One such family is that of a poor wife who is waiting for her husband to come home for dinner. The wife has prepared a simple meal of rice and lentils, but her husband has not returned from work yet. As the hours pass, the wife becomes increasingly worried and anxious, but she continues to wait patiently with a burning candle by her side. This candle represents her hope and faith that her husband will come home safely and they will be able to share a meal together as a family. Despite the challenges they face, this poor wife's love and dedication to her family shines through",
    description:
      "In India, there are many families who struggle to make ends meet and provide for their basic needs. One such family is that of a poor wife who is waiting for her husband to come home for dinner. The wife has prepared a simple meal of rice and lentils, but her husband has not returned from work yet. As the hours pass, the wife becomes increasingly worried and anxious, but she continues to wait patiently with a burning candle by her side. This candle represents her hope and faith that her husband will come home safely and they will be able to share a meal together as a family. Despite the challenges they face, this poor wife's love and dedication to her family shines through",
    imagesrc: [{ src: "./images/burningTogether_image.jpg" }],
    videosrc: "",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
  {
    id: uuid(),
    name: "Fifth Dimension",
    prefix: "Painting",
    title: "Fifth Dimension",
    subtitle: "Beauty is the fifth Dimension",
    description: "",
    imagesrc: [{ src: "./images/fifth_dimension_image.jpg" }],
    videosrc: "",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
  {
    id: uuid(),
    name: "Waiting",
    prefix: "Painting",
    title: "Waiting",
    subtitle: "A simple Illustration",
    description: "",
    imagesrc: [{ src: "./images/Ilu_Image3.jpg" }],
    videosrc: "",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
  {
    id: uuid(),
    name: "Who",
    prefix: "Painting",
    title: "Who",
    subtitle: "An identity crises",
    description: "",
    imagesrc: [{ src: "./images/who_image.jpg" }],
    videosrc: "",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
  {
    id: uuid(),
    name: "Ayan Rand",
    prefix: "Favorite Reading",
    title: "Ayan Rand",
    subtitle: "Atlas Shrugged",
    description: "",
    imagesrc: [{ src: "./images/atlusshrugged.png" }],
    videosrc: "",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
  {
    id: uuid(),
    name: "Mirza Ghalib",
    prefix: "Favorite Reading",
    title: "Mirza Ghalib",
    subtitle: "divine poetry by Mirza Ghalib",
    description: "",
    imagesrc: [{ src: "./images/mirzaghalib.png" }],
    videosrc: "",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
  {
    id: uuid(),
    name: "Dan Brown",
    prefix: "Favorite Reading",
    title: "Dan Brown",
    subtitle: "Da Vinci Code",
    description: "",
    imagesrc: [{ src: "./images/davincicode.png" }],
    videosrc: "",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
];
