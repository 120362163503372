import { useState, useCallback, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ExperienceModal from "../components/ExperienceModal";
import CompaniesModal from "../components/CompaniesModal";
import UxprocessModal from "../components/UxprocessModal";
import TemperatureMonitoringQuestionModal from "../components/TemperatureMonitoringQuestionModal";
import TemperatureMonitoringAnswerModal from "../components/TemperatureMonitoringAnswerModal";

import KnowledgeModal from "../components/KnowledgeModal";

import styles from "../css/image.module.css";
import Image from "./image";
import workData from "../datajs/workdata";
import ideasData from "../datajs/ideasdata";
import artData from "../datajs/artdata";
import ReactPlayer from "react-player";

const Home = () => {
  const [showExperienceModal, setShowExperienceModal] = useState(false);
  const [showCompaniesModal, setshowCompaniesModal] = useState(false);
  const [showUxprocessModal, setshowUxprocessModal] = useState(false);
  const [showTempQuestionModal, setshowTempQuestionModal] = useState(false);
  const [showTempAnswerModal, setshowTempAnswerModal] = useState(false);

  const [showKnowledgeModal, setshowKnowledgeModal] = useState(false);
  function showExperienceModalFunction() {
    setShowExperienceModal(true);
  }
  const hideExperienceModalFunction = () => {
    setShowExperienceModal(false);
  };

  function showCompaniesModalFunction() {
    setshowCompaniesModal(true);
  }
  const hideCompaniesModalFunction = () => {
    setshowCompaniesModal(false);
  };

  function showUxprocessModalFunction() {
    setshowUxprocessModal(true);
  }
  const hideUxprocessModalFunction = () => {
    setshowUxprocessModal(false);
  };

  function showTempQuestionModalFunction() {
    setshowTempQuestionModal(true);
  }
  const hideTempQuestionModalFunction = () => {
    setshowTempQuestionModal(false);
  };

  function showTempAnswerModalFunction() {
    setshowTempAnswerModal(true);
  }
  const hideTempAnswerModalFunction = () => {
    setshowTempAnswerModal(false);
  };

  function showKnowledgeModalFunction() {
    setshowKnowledgeModal(true);
  }
  const hideKnowledgeModalFunction = () => {
    setshowKnowledgeModal(false);
  };

  function exploreQuestion(item) {
    switch (item.name) {
      case "Temperature Monitoring System":
        showTempQuestionModalFunction();
        break;

      default:
        break;
    }
  }
  function exploreAnswers(item) {
    switch (item.name) {
      case "Temperature Monitoring System":
        showTempAnswerModalFunction();
        break;

      default:
        break;
    }
  }
  function openPDF() {
    window.open("https://sohailfx.com/uxprocess.pdf", "_blank");
  }

  return (
    <>
      <div className="content ">
        <div className="container" style={{ marginBottom: 50 }}>
          <div className="row align-items-start">
            <div className="col">
              <div
                className=""
                style={{
                  paddingTop: 40,
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                <div className="d-flex align-items-start justify-content-between">
                  <div
                    style={{
                      width: 150,
                      marginRight: 50,
                      marginLeft: 15,
                    }}
                  >
                    <img
                      src="./data/logo.png"
                      alt="Company logo"
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                  <span
                    className="fs-5 text-dark"
                    style={{ position: "relative", top: 0, textAlign: "end" }}
                  >
                    Meet, there is nothing more experiencing than meeting
                    people.
                    {/* Life is too precious for bad user experience! */}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" text-center">
          <div className="container">
            <div style={{ marginBottom: 50 }}></div>
            <div>
              <div className="row p-5" style={{ backgroundColor: "black" }}>
                <div className="col-sm-12">
                  <div className=" overflow-hidden">
                    <Image
                      src="./images/bookcover.png"
                      border={true}
                      zoom={true}
                    ></Image>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 d-flex align-items-center flex-column">
                  <div className="m-5 mb-0">
                    <div>
                      <span className="" style={{ fontSize: 32 }}>
                        Join a delightful mother-daughter duo in this inspiring
                        and amusing story.
                      </span>
                    </div>
                    <div
                      style={{
                        borderBottom: "solid 8px black",
                        width: 80,
                        margin: "24px auto",
                      }}
                    ></div>
                    <div>
                      <span
                        className=""
                        style={{ fontSize: 32, color: "orange" }}
                      >
                        Picture Story Book
                      </span>
                    </div>
                  </div>
                  <div className="m-5 small text-start">
                    <p>
                      The story itself is a serendipity. Every other night, my
                      daughters would ask me to tell them a story, and I'd
                      create tales from various sources. Then, one night, I
                      began spinning an imaginary story. My daughters started
                      adding their own events, and after a few nights, it
                      evolved into a unique, original narrative. Their
                      inspiration led me to transform it into a book.
                    </p>
                    <p>
                      Each picture in the book is an original, hand-painted
                      water colour illustration. Every paragraph and sentence
                      are thoughtfully crafted for children, aiming for easy
                      understanding and sustained interest. Each page is
                      designed to maintain engagement, with a consistent reading
                      pace and an element of suspense.
                    </p>
                    <p>
                      In today's world filled with sci-fi, action, violence, and
                      alien fantasy stories, I aimed to create a simple,
                      classical, amusing story that offers life-changing
                      motivation and inspiration. This book is a humble gift to
                      my loved ones and yours.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-line-text-wrap text-center">
          <div className="container">
            <div style={{ marginBottom: 50 }}></div>
            <div style={{ marginBottom: "16px" }}>
              <div className="row">
                <div className="col-sm-12 col-md-2 mb-5">
                  <div className="rounded-circle overflow-hidden">
                    <Image
                      src="./images/sohil.png"
                      border={true}
                      zoom={true}
                    ></Image>
                  </div>
                </div>
                <div className="col-sm-12 col-md-10 d-flex align-items-center flex-column">
                  <div className="mb-5 mt-3">
                    <div>
                      <span
                        className=""
                        style={{ fontSize: 32, color: "white" }}
                      >
                        About Me
                      </span>
                    </div>
                    <div
                      style={{
                        borderBottom: "solid 1px white",
                        width: 80,
                        margin: "0 auto",
                      }}
                    ></div>
                  </div>
                  <div className="mb-3">
                    <span className="banner-line-text">
                      Experienced UX Designer, Excellent Software Engineer(B.E),
                      and Enthusiastic{" "}
                      <a
                        href="#artisticSection"
                        className="ms-2"
                        style={{
                          color: "#ddd",
                          textDecoration: "none",
                          display: "inline-block",
                          borderBottom: "solid 2px blue",
                        }}
                      >
                        Artist
                      </a>
                    </span>
                  </div>
                  <div className="banner-line-text mb-3">
                    <span style={{ color: "#ddd", fontSize: 18 }}>
                      Certified member of "Interaction-design" Foundation with 6
                      distinct{" "}
                      <a
                        href="#certificateSection"
                        style={{
                          textDecoration: "none",
                          display: "inline-block",
                          borderBottom: "solid 2px blue",
                        }}
                      >
                        <span style={{ color: "#ddd", fontSize: 18 }}>
                          Certificates.
                        </span>
                      </a>
                    </span>
                  </div>
                  <div className="banner-line-text">
                    <p
                      style={{
                        color: "#ddd",
                        fontSize: 18,
                      }}
                    >
                      I devote my spare time to coffee, calligraphy and{" "}
                      <a
                        href="#ideasSection"
                        style={{
                          textDecoration: "none",
                          display: "inline-block",
                          borderBottom: "solid 2px blue",
                        }}
                      >
                        <span
                          style={{
                            color: "#ddd",
                            fontSize: 18,
                          }}
                        >
                          Creativity.
                        </span>
                      </a>
                    </p>
                  </div>
                  <div className="banner-line-text">
                    <p
                      style={{
                        color: "orange",
                        fontSize: 20,
                        marginBottom: 50,
                      }}
                    >
                      Let's make humanity-centered designs
                    </p>
                    <p style={{ color: "#ddd", fontSize: 18 }}>
                      sohailfx@gmail.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{ marginBottom: 100 }}>
          <div className="row row-cols-1 row-cols-md-3 g-4">
            <div className="col text-center ">
              <div
                className="card custom-tile h-100"
                style={{ cursor: "pointer" }}
                onClick={() => showExperienceModalFunction()}
              >
                <div className="card-body ps-0">
                  <div className="color-blue">
                    <span className="me-3 fs-5">
                      <i className="fa-regular fa-award"></i>
                    </span>
                    <span className="button-style">RELEVANT EXPERIENCE</span>
                  </div>
                  <div className="mb-3">
                    <span className="tile-headline" style={{ fontSize: 80 }}>
                      12+
                    </span>
                  </div>
                  <div>
                    <span className="tile-paragraph-text">
                      12+ years of extensive experience working with a diverse
                      range of professionals at various levels, including
                      Product Managers, Sales and Marketing Teams, Development
                      teams, QA teams, and even CeXOs.
                    </span>
                  </div>
                  <div className="mt-3">
                    <div className="d-flex justify-content-evenly">
                      <div
                        className="rounded-3 overflow-hidden border "
                        title="User Interface Design"
                      >
                        <img
                          src="./images/ui.png"
                          className="rounded mx-auto d-block"
                          alt="..."
                        ></img>
                      </div>
                      <div
                        className="rounded-3 overflow-hidden border "
                        title="User Experience Design"
                      >
                        <img
                          src="./images/ux.png"
                          className="rounded mx-auto d-block"
                          alt="..."
                        ></img>
                      </div>
                      <div
                        className="rounded-3 overflow-hidden border "
                        title="Interaction Design "
                      >
                        <img
                          src="./images/interactiondesign.png"
                          className="rounded mx-auto d-block"
                          alt="..."
                        ></img>
                      </div>
                      <div
                        className="rounded-3 overflow-hidden border "
                        title="HTML, CSS, React Coding "
                      >
                        <img
                          src="./images/coding.png"
                          className="rounded mx-auto d-block"
                          alt="..."
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col text-center">
              <div
                className="card h-100 custom-tile"
                style={{ cursor: "pointer" }}
                onClick={() => showCompaniesModalFunction()}
              >
                <div className="card-body">
                  <div className="color-orange">
                    <span className="me-3 fs-5">
                      <i className="fa-regular fa-building-columns"></i>
                    </span>
                    <span className="button-style">COMPANIES</span>
                  </div>

                  <div className="mb-3">
                    <span className="tile-headline" style={{ fontSize: 80 }}>
                      4+
                    </span>
                  </div>
                  <div>
                    <span className="tile-paragraph-text">
                      4+ Multi national companies: Had the opportunity to worked
                      in several domain expert companies such as Communication,
                      ERP, Analytics and IoT. <br></br> INFOR, CISCO, Planful,
                      and ORBCOMM, in various UX roles.
                    </span>
                  </div>
                  <div className="mt-3">
                    <div className="d-flex justify-content-evenly">
                      <div
                        className="rounded-3 overflow-hidden  "
                        title="Infor"
                      >
                        <img
                          src="./images/infothumb.png"
                          className="rounded mx-auto d-block"
                          alt="..."
                        ></img>
                      </div>
                      <div
                        className="rounded-3 overflow-hidden  "
                        title="CISCO"
                      >
                        <img
                          src="./images/ciscothumb.png"
                          className="rounded mx-auto d-block"
                          alt="..."
                        ></img>
                      </div>
                      <div
                        className="rounded-3 overflow-hidden  "
                        title="Planful"
                      >
                        <img
                          src="./images/planfulthumb.png"
                          className="rounded mx-auto d-block"
                          alt="..."
                        ></img>
                      </div>
                      <div
                        className="rounded-3 overflow-hidden  "
                        title="ORBCOMM"
                      >
                        <img
                          src="./images/orbcommthumb.png"
                          className="rounded mx-auto d-block"
                          alt="..."
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col text-center">
              <div
                className="card custom-tile h-100 pe-0"
                style={{ cursor: "pointer" }}
                onClick={() => showKnowledgeModalFunction()}
              >
                <div className="card-body">
                  <div className="color-green">
                    <span className="me-3 fs-5">
                      <i className="fa-regular fa-lightbulb-on"></i>
                    </span>
                    <span className="button-style">DESIGN SKILLS</span>
                  </div>

                  <div className="mb-3">
                    <span className="tile-headline" style={{ fontSize: 80 }}>
                      10+
                    </span>
                  </div>
                  <div>
                    <span className="tile-paragraph-text ">
                      Extensive proficiency in conducting UX research and
                      gathering functional requirements. Moreover, I possess
                      exceptional knowledge of UI/UX technologies, including
                      React Js, Figma, Photoshop, HTML, CSS other related tools.
                    </span>
                  </div>
                  <div className="mt-3">
                    <div className="d-flex justify-content-evenly">
                      <div
                        className="rounded-3 overflow-hidden  "
                        title="HTML CSS"
                      >
                        <img
                          src="./images/htm5thumb.png"
                          className="rounded mx-auto d-block"
                          alt="..."
                        ></img>
                      </div>
                      <div
                        className="rounded-3 overflow-hidden  "
                        title="Photoshop"
                      >
                        <img
                          src="./images/photoshopthumb.png"
                          className="rounded mx-auto d-block"
                          alt="..."
                        ></img>
                      </div>
                      <div
                        className="rounded-3 overflow-hidden  "
                        title="FIGMA"
                      >
                        <img
                          src="./images/figmathumb.png"
                          className="rounded mx-auto d-block"
                          alt="..."
                        ></img>
                      </div>
                      <div
                        className="rounded-3 overflow-hidden  "
                        title="React JS"
                      >
                        <img
                          src="./images/reatthumb.png"
                          className="rounded mx-auto d-block"
                          alt="..."
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="text-center p-5 mb-5"
          style={{
            backgroundColor: "black",
          }}
        >
          <div className="row row-cols-1 mb-4">
            <div style={{ marginBottom: 8 }}>
              <div>
                <span className="" style={{ fontSize: 32, color: "white" }}>
                  How do I do it?
                </span>
              </div>
              <div
                style={{
                  borderBottom: "solid 1px white",
                  width: 80,
                  margin: "0 auto",
                }}
              ></div>
            </div>
          </div>
          <div
            className="row row-cols-1 row-cols-md-4 justify-content-center g-4"
            style={{ marginBottom: 100 }}
          >
            <div className="col text-center">
              <a
                className="btn btn-light button-style-wrap"
                href="./pdf/designdocument.pdf"
                role="button"
                target="_blank"
              >
                <div className="mb-3">
                  <span className="icon" style={{ color: "white" }}>
                    <i className="fa-light fa-book"></i>
                  </span>
                </div>
                <div>
                  <span className="button-style me-3">RECENT CASE STUDY</span>
                </div>
              </a>
            </div>
            <div className="col text-center">
              <a
                className="btn btn-light button-style-wrap"
                href="./pdf/uxprocess.pdf"
                role="button"
                target="_blank"
              >
                <div className="mb-3">
                  <span className="icon" style={{ color: "white" }}>
                    <i className="fa-light fa-compass-drafting"></i>
                  </span>
                </div>
                <div>
                  <span className="button-style me-3">DESIGN PROCESS</span>
                </div>
              </a>
            </div>
          </div>
          <div className="row row-cols-1 mt-5">
            <div style={{ marginBottom: 50 }}>
              <div>
                <span className="" style={{ fontSize: 32, color: "white" }}>
                  How can I save time?
                </span>
              </div>
              <div
                style={{
                  borderBottom: "solid 1px white",
                  width: 150,
                  margin: "0 auto",
                }}
              ></div>
            </div>
          </div>
          <div className="row row-cols-1">
            <div className="col " style={{ color: "white" }}>
              <div>
                <div className="mb-5">
                  <div className="mb-3">
                    <p style={{ color: "#ddd", fontSize: 18 }}>
                      I assist in saving time and money while boosting product
                      confidence.
                    </p>
                  </div>
                  <div className="mb-3">
                    <span className="text fs-6">
                      In addition to excelling in user experience design, I
                      contribute to <strong> time savings</strong> for three
                      teams: Product Management, Sales, and the Engineering
                      Team.
                    </span>
                  </div>
                  <div className="mb-3">
                    <span
                      className="text fw-bold fs-5"
                      style={{ color: "orange" }}
                    >
                      By creating interactive HTML, CSS and React JS ready to
                      use prototypes as a blueprint for front-end development.
                    </span>
                  </div>
                  <div>
                    <span className="text fs-6">
                      Accelerate product development by rapidly creating
                      interactive prototypes, enabling quicker feedback and
                      decision-making.
                    </span>
                  </div>
                  <div>
                    <span className="text fs-6">
                      Enable sales teams to demonstrate product features and
                      functionality effectively with ready-to-use prototypes
                      during client meetings.
                    </span>
                  </div>
                  <div>
                    <span className="text fs-6">
                      Streamline the development process by delivering fully
                      functional HTML, CSS, and React.js prototypes as a
                      blueprint for front-end development.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container text-center">
          <div
            id="certificateSection"
            className="row align-items-start"
            style={{ marginBottom: 64 }}
          ></div>
          <div className="mb-5">
            <h3>Certificates</h3>
            <div>
              <div className="row row-cols-1 row-cols-md-3 g-4">
                <div className="col text-center p-2">
                  <div className="image-parent-div p-3">
                    <Image
                      src={"./images/certificate3.jpg"}
                      border={true}
                      zoom={true}
                    ></Image>
                    <div>
                      <h5>UX Management</h5>
                    </div>

                    <div className="m-3">
                      <a
                        class="btn btn-dark"
                        href="https://www.interaction-design.org/members/syed-sohil-anjum/certificate/course/d5403ef7-3f20-4a44-91c3-b70910c55d49"
                        role="button"
                        target="_blank"
                      >
                        View Certificate
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col text-center p-2">
                  <div className="image-parent-div p-1">
                    <Image
                      src={"./images/certificate1.jpg"}
                      border={true}
                      zoom={true}
                    ></Image>
                    <div>
                      <h5>Mobile UX Design</h5>
                    </div>

                    <div className="m-3">
                      <a
                        class="btn btn-dark"
                        href="https://www.interaction-design.org/members/syed-sohil-anjum/certificate/course/48b9eaf9-a84e-4cd1-9e69-de1f555b7608"
                        role="button"
                        target="_blank"
                      >
                        View Certificate
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col text-center p-2">
                  <div className="image-parent-div p-3">
                    <Image
                      src={"./images/certificate2.jpg"}
                      border={true}
                      zoom={true}
                    ></Image>
                    <div>
                      <h5>Design Thinking</h5>
                    </div>

                    <div className="m-3">
                      <a
                        class="btn btn-dark"
                        href="https://www.interaction-design.org/members/syed-sohil-anjum/certificate/course/0a0eebe5-d2e8-486f-867a-96f1f82796a8"
                        role="button"
                        target="_blank"
                      >
                        View Certificate
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row-cols-1 row-cols-md-3 g-4">
                <div className="col text-center p-2">
                  <div className="image-parent-div p-3">
                    <Image
                      src={"./images/certificate4.jpg"}
                      border={true}
                      zoom={true}
                    ></Image>
                    <div>
                      <h5>AI for Designers</h5>
                    </div>

                    <div className="m-3">
                      <a
                        class="btn btn-dark"
                        href="https://www.interaction-design.org/members/syed-sohil-anjum/certificate/course/c1e44d18-86eb-444f-9d13-13e3817549b6"
                        role="button"
                        target="_blank"
                      >
                        View Certificate
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col text-center p-2">
                  <div className="image-parent-div p-1">
                    <Image
                      src={"./images/certificate5.jpg"}
                      border={true}
                      zoom={true}
                    ></Image>
                    <div>
                      <h5>Agile Methods for UX Design</h5>
                    </div>

                    <div className="m-3">
                      <a
                        class="btn btn-dark"
                        href="https://www.interaction-design.org/members/syed-sohil-anjum/certificate/course/a5fd3627-0175-437a-a7ce-8b6d90a6a7e1"
                        role="button"
                        target="_blank"
                      >
                        View Certificate
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col text-center p-2">
                  <div className="image-parent-div p-3">
                    <Image
                      src={"./images/certificate6.jpg"}
                      border={true}
                      zoom={true}
                    ></Image>
                    <div>
                      <h5>User Research – Best Practices</h5>
                    </div>

                    <div className="m-3">
                      <a
                        class="btn btn-dark"
                        href="https://www.interaction-design.org/members/syed-sohil-anjum/certificate/course/8081bc4a-d193-4ad8-867c-923c0da1879b"
                        role="button"
                        target="_blank"
                      >
                        View Certificate
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="text-center p-5 mb-5"
          style={{
            backgroundColor: "black",
          }}
        >
          <div className="row row-cols-1 mb-4">
            <div style={{ marginBottom: 8 }}>
              <div className="mb-3">
                <span className="" style={{ fontSize: 32, color: "white" }}>
                  Why AI can't replace me (Us) ?
                </span>
              </div>
              <div
                style={{
                  borderBottom: "solid 1px white",
                  width: 350,
                  margin: "0 auto",
                }}
              ></div>
            </div>
          </div>
          <div className="row row-cols-1">
            <div className="col " style={{ color: "white" }}>
              <div>
                <div className="mb-5">
                  <div className="mb-3">
                    <h3 style={{ color: "orange" }}>
                      1: Thinking - Critical thinking{" "}
                    </h3>
                    <p style={{ color: "#ddd", fontSize: 18 }}>
                      Critical Considering or reasoning about something—so far,
                      AI cannot do that in real-time situations. Analyzing
                      information and making the right judgment is, or should
                      be, a uniquely human ability.
                    </p>
                  </div>
                  <div className="mb-3">
                    <h3 style={{ color: "orange" }}>2: Imagination</h3>
                    <p style={{ color: "#ddd", fontSize: 18 }}>
                      We are born to imagine. AI cannot replace that. AI is like
                      a dream. Dreams are constructed by the brain out of only
                      what we see, hear, smell, or feel. But imaginations are
                      completely out of nothing—something new, something
                      extraordinary.
                    </p>
                  </div>
                  <div className="mb-3">
                    <h3 style={{ color: "orange" }}>3: Empathy</h3>
                    <p style={{ color: "#ddd", fontSize: 18 }}>
                      AI cannot truly understand human emotions, motivations,
                      and nuances in the way a human can, because AI cannot
                      feel, and real empathy is built on understanding feelings.
                    </p>
                  </div>
                  <div className="mb-3">
                    <span className="text fs-6">
                      and then there is collaboration, system understanding,
                      timing, history, culture .. it will take time to get there
                      .. may be
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ backgroundColor: "#f5f5f7" }}>
          <div style={{ padding: 80, textAlign: "center" }}>
            <span
              className=""
              style={{
                fontSize: "48px",
                fontWeight: 700,
                letterSpacing: "1px",
              }}
            >
              My Work
            </span>
            <div>
              <span>A few of the 50+ solutions I’ve built over the years.</span>
            </div>
          </div>
          <div className="container">
            {workData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="row justify-content-md-center"
                  style={{ marginBottom: 64 }}
                >
                  <div className="col-sm-12 col-lg-9 text-center">
                    <div className="image-parent-div">
                      <div className="p-3">
                        <div className="m-3">
                          <span className="tile-headline">{item.title}</span>
                        </div>
                        <div>
                          <span className="tile-paragraph-text text-secondary">
                            {item.subtitle}
                          </span>
                        </div>
                      </div>
                      <div>
                        {item.imagesrc.map((imageItem, index2) => {
                          return (
                            <Image
                              src={imageItem.src}
                              border={true}
                              zoom={true}
                            ></Image>
                          );
                        })}
                      </div>
                      <div className="row row-cols-1 row-cols-md-3 justify-content-md-center m-3">
                        {item.question && item.question != "" ? (
                          <div className="col">
                            <div
                              className="mb-3 custom-tile text-start p-3 mouse-hover"
                              style={{
                                overflow: "hidden",
                                height: 239,
                              }}
                              onClick={() => exploreQuestion(item)}
                            >
                              <div className="mb-3 pb-1">
                                <span className="color-orange">QUESTION</span>
                              </div>
                              <div>
                                <span className="text-start fs-5 text-secondary fs-6">
                                  {item.question}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {item.videosrc && item.videosrc != "" ? (
                          <div className="col">
                            <div
                              className="mb-3 custom-tile mouse-hover"
                              style={{
                                overflow: "hidden",
                                height: 239,
                              }}
                            >
                              <video width="320" height="240" controls>
                                <source src={item.videosrc} type="video/mp4" />
                              </video>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {item.answer && item.answer != "" ? (
                          <div className="col">
                            <div
                              className="mb-3 custom-tile text-start p-3 mouse-hover"
                              style={{
                                overflow: "hidden",
                                height: 239,
                              }}
                              onClick={() => exploreAnswers(item)}
                            >
                              <div className="mb-3 pb-1">
                                <span className="color-green">ANSWER</span>
                              </div>
                              <div>
                                <span className="text-start fs-6 text-dark">
                                  {item.answer}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              id="ideasSection"
              className="row align-items-start"
              style={{ marginBottom: 64 }}
            ></div>

            <div style={{ marginBottom: 64 }}></div>
          </div>
          <div style={{ padding: 80, textAlign: "center" }}>
            <span
              className=""
              style={{
                fontSize: "48px",
                fontWeight: 700,
                letterSpacing: "1px",
              }}
            >
              Ideas
            </span>
            <div>
              <span>A few of the selected creative work</span>
            </div>
          </div>
          <div className="container">
            {ideasData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="row justify-content-md-center"
                  style={{ marginBottom: 64 }}
                >
                  <div className="col-sm-12 col-lg-9 text-center">
                    <div className="image-parent-div">
                      <div className="p-3">
                        <div className="m-3">
                          <span className="tile-headline">{item.title}</span>
                        </div>
                        <div>
                          <span className="tile-paragraph-text text-secondary">
                            {item.subtitle}
                          </span>
                        </div>
                      </div>
                      <div>
                        {item.imagesrc.map((imageItem, index2) => {
                          return (
                            <Image
                              src={imageItem.src}
                              border={true}
                              zoom={true}
                            ></Image>
                          );
                        })}
                      </div>
                      <div className="row row-cols-1 row-cols-md-3 justify-content-md-center m-3">
                        {item.question && item.question != "" ? (
                          <div className="col">
                            <div
                              className="mb-3 custom-tile text-start p-3 mouse-hover"
                              style={{
                                overflow: "hidden",
                                height: 239,
                              }}
                              onClick={() => exploreQuestion(item)}
                            >
                              <div className="mb-3 pb-1">
                                <span className="color-orange">QUESTION</span>
                              </div>
                              <div>
                                <span className="text-start fs-5 text-secondary fs-6">
                                  {item.question}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {item.videosrc && item.videosrc != "" ? (
                          <div className="col">
                            <div
                              className="mb-3 custom-tile mouse-hover"
                              style={{
                                overflow: "hidden",
                                height: 239,
                              }}
                            >
                              <video width="320" height="240" controls>
                                <source src={item.videosrc} type="video/mp4" />
                              </video>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {item.answer && item.answer != "" ? (
                          <div className="col">
                            <div
                              className="mb-3 custom-tile text-start p-3 mouse-hover"
                              style={{
                                overflow: "hidden",
                                height: 239,
                              }}
                              onClick={() => exploreAnswers(item)}
                            >
                              <div className="mb-3 pb-1">
                                <span className="color-green">ANSWER</span>
                              </div>
                              <div>
                                <span className="text-start fs-6 text-dark">
                                  {item.answer}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              id="artisticSection"
              className="row align-items-start"
              style={{ marginBottom: 64 }}
            ></div>

            <div style={{ marginBottom: 64 }}></div>
          </div>
          <div style={{ padding: 80, textAlign: "center" }}>
            <span
              className=""
              style={{
                fontSize: "48px",
                fontWeight: 700,
                letterSpacing: "1px",
              }}
            >
              Creativity
            </span>
            <div>
              <span>A few of the selected creative work</span>
            </div>
          </div>
          <div className="container">
            {artData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="row justify-content-md-center"
                  style={{ marginBottom: 64 }}
                >
                  <div className="col-sm-12 col-lg-9 text-center">
                    <div className="image-parent-div">
                      <div className="p-3">
                        <div className="m-3">
                          <span className="tile-headline">{item.title}</span>
                        </div>
                        <div>
                          <span className="tile-paragraph-text text-secondary">
                            {item.subtitle}
                          </span>
                        </div>
                      </div>
                      <div>
                        {item.imagesrc.map((imageItem, index2) => {
                          return (
                            <Image
                              src={imageItem.src}
                              border={true}
                              zoom={true}
                            ></Image>
                          );
                        })}
                      </div>
                      <div className="row row-cols-1 row-cols-md-3 justify-content-md-center m-3">
                        {item.question && item.question != "" ? (
                          <div className="col">
                            <div
                              className="mb-3 custom-tile text-start p-3 mouse-hover"
                              style={{
                                overflow: "hidden",
                                height: 239,
                              }}
                              onClick={() => exploreQuestion(item)}
                            >
                              <div className="mb-3 pb-1">
                                <span className="color-orange">QUESTION</span>
                              </div>
                              <div>
                                <span className="text-start fs-5 text-secondary fs-6">
                                  {item.question}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {item.videosrc && item.videosrc != "" ? (
                          <div className="col">
                            <div
                              className="mb-3 custom-tile mouse-hover"
                              style={{
                                overflow: "hidden",
                                height: 239,
                              }}
                            >
                              <video width="320" height="240" controls>
                                <source src={item.videosrc} type="video/mp4" />
                              </video>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {item.answer && item.answer != "" ? (
                          <div className="col">
                            <div
                              className="mb-3 custom-tile text-start p-3 mouse-hover"
                              style={{
                                overflow: "hidden",
                                height: 239,
                              }}
                              onClick={() => exploreAnswers(item)}
                            >
                              <div className="mb-3 pb-1">
                                <span className="color-green">ANSWER</span>
                              </div>
                              <div>
                                <span className="text-start fs-6 text-dark">
                                  {item.answer}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div style={{ backgroundColor: "black" }}>
          <div>
            <div
              style={{
                paddingTop: 64,
                paddingBottom: 100,
                textAlign: "center",
              }}
            >
              <div className="mb-3">
                <span className="fs-1" style={{ color: "white" }}>
                  Contact
                </span>
              </div>
              <div className="mb-3">
                <span style={{ color: "white" }}>
                  I’m based in the Hyderabad - India
                </span>
              </div>
              <div>
                <span style={{ color: "white" }}>sohailfx@gmail.com</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ExperienceModal
        show={showExperienceModal}
        handleClose={hideExperienceModalFunction}
      />

      <CompaniesModal
        show={showCompaniesModal}
        handleClose={hideCompaniesModalFunction}
      />

      <UxprocessModal
        show={showUxprocessModal}
        handleClose={hideUxprocessModalFunction}
      />

      <KnowledgeModal
        show={showKnowledgeModal}
        handleClose={hideKnowledgeModalFunction}
      />
      <TemperatureMonitoringQuestionModal
        show={showTempQuestionModal}
        handleClose={hideTempQuestionModalFunction}
      />
      <TemperatureMonitoringAnswerModal
        show={showTempAnswerModal}
        handleClose={hideTempAnswerModalFunction}
      />
    </>
  );
};

export default Home;
