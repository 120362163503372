import { v4 as uuid } from "uuid";

export default [
  {
    id: uuid(),
    name: "DigiMouse",
    prefix: "Personal Idea",
    title: "DigiMouse",
    subtitle: "A must mouse for almost every professional",
    description: "",
    imagesrc: [{ src: "./images/digimouse.jpg" }],
    videosrc: "",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
  {
    id: uuid(),
    name: "Personal Scheduler",
    prefix: "Personal Idea",
    title: "Personal Scheduler",
    subtitle: "An idea for wrist watch",
    description: "",
    imagesrc: [
      { src: "./images/schedulerWatch.png" },
      { src: "./images/scheduler2.png" },
      { src: "./images/scheduler3.png" },
      { src: "./images/scheduler1.png" },
    ],
    videosrc: "",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
  {
    id: uuid(),
    name: "Car Toaster",
    prefix: "Personal Idea",
    title: "Car Toaster",
    subtitle: "A quick solution for breakfast lover",
    description: "",
    imagesrc: [
      { src: "./images/carToaster1.jpg" },
      { src: "./images/carToaster2.jpg" },
    ],
    videosrc: "",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
];
