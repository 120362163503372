import React from "react";
import { useEffect, useState, useRef } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  NavLink,
  useNavigate,
} from "react-router-dom";
import { Modal, Accordion, Offcanvas, Button } from "react-bootstrap";
import Image from "../pages/image";

export default function TemperatureMonitoringQuestionModal(props) {
  const { show, handleClose } = props;

  return (
    <>
      <Modal show={show} onHide={handleClose} scrollable={true} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Temperature Monitoring System</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ minHeight: "75vh", maxHeight: "65vh", overflowY: "auto" }}
        >
          <div className="container">
            <div className="mb-3">
              <span className="fs-1">Case Study</span>
            </div>
            <div className="mb-4">
              <div>
                <span className="fs-3">Problem Statement</span>
              </div>
              <div>
                <span>
                  How can the monitoring system for Reefer Trailers be improved
                  using IoT technology and better UX Design?
                </span>
              </div>
            </div>
            <div className="mb-4">
              <div>
                <span className="fs-3">Persona</span>
              </div>
              <div>
                <span>Fleet Manager</span>
              </div>
            </div>
            <div className="mb-3">
              <div>
                <span className="fs-3">
                  Pain Points & Problems (The Why Part)
                </span>
              </div>
              <div className="mb-3">
                <span className="text-secondary">
                  As a Reefer fleet manager, there are several pain points that
                  can impact the performance and profitability of business. Here
                  are a few common pain points:
                </span>
              </div>
              <div>
                <ul>
                  <li>
                    <div className="mb-3">
                      <span className="text-secondary">
                        <strong>Temperature Management: </strong>
                        One of the biggest pain points for Reefer fleet managers
                        is maintaining the proper temperature inside the
                        trailers. Any deviation from the desired temperature
                        range can result in spoilage, leading to financial
                        losses.
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="mb-3">
                      <span className="text-secondary">
                        <strong>Location Tracking: </strong> Knowing the
                        location of your Reefer trailers in real-time can help
                        you optimize routes, reduce fuel consumption, and
                        improve operational efficiency. With location tracking,
                        fleet managers can also ensure the safety of their cargo
                        by tracking trailers in real-time and knowing when they
                        deviate from their intended route.
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="mb-3">
                      <span className="text-secondary">
                        <strong>Alert Management: </strong>
                        Reefer trailers require a specific temperature range to
                        ensure the quality and safety of the cargo. Alert
                        management allows fleet managers to receive
                        notifications when temperature, humidity, or other
                        critical parameters fall outside of the desired range.
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
