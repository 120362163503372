import React from "react";
import { useEffect, useState, useRef } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  NavLink,
  useNavigate,
} from "react-router-dom";
import { Modal, Accordion, Offcanvas, Button } from "react-bootstrap";
import Image from "../pages/image";

export default function Experience(props) {
  const { show, handleClose } = props;

  return (
    <>
      <Modal show={show} onHide={handleClose} scrollable={true} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Experience (12+ Years)</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ minHeight: "75vh", maxHeight: "65vh", overflowY: "auto" }}
        >
          <div className="container">
            <div>
              <div className="mb-5">
                <div className="d-flex">
                  <div
                    className="company-logo-container"
                    style={{ width: 180 }}
                  >
                    <Image
                      src="./images/orbcommlogo.png"
                      border={true}
                      zoom={true}
                      className="logo-image"
                    ></Image>
                  </div>
                  <div className="flex-1">
                    <div>
                      <span className="text fw-bold">
                        Senior User Experience Designer
                      </span>
                    </div>
                    <div className="mb-3">
                      <span className="text">Oct 2016 - Present · 7 yrs</span>
                    </div>
                    <div>
                      <span className="text">ORBCOMM</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <div className="d-flex">
                  <div
                    className="company-logo-container"
                    style={{ width: 180 }}
                  >
                    <Image
                      src="./images/planfullogo.png"
                      border={true}
                      zoom={true}
                      className="logo-image"
                    ></Image>
                  </div>
                  <div className="flex-1">
                    <div>
                      <span className="text fw-bold">
                        Senior Interaction Designer
                      </span>
                    </div>
                    <div className="mb-3">
                      <span className="text">
                        Apr 2012 - August 2016 · 4 yrs 5 mos
                      </span>
                    </div>
                    <div>
                      <span className="text">
                        Planful (Host Analytics, Inc.)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <div className="d-flex">
                  <div
                    className="company-logo-container"
                    style={{ width: 180 }}
                  >
                    <Image
                      src="./images/ciscologo.png"
                      border={true}
                      zoom={true}
                      className="logo-image"
                    ></Image>
                  </div>
                  <div className="flex-1">
                    <div>
                      <span className="text fw-bold">
                        Principal Software Engineer (UI)
                      </span>
                    </div>
                    <div className="mb-3">
                      <span className="text">
                        Jul 2010 - Apr 2012 · 1 yr 10 mos
                      </span>
                    </div>
                    <div>
                      <span className="text">CISCO</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <div className="d-flex">
                  <div
                    className="company-logo-container"
                    style={{ width: 180 }}
                  >
                    <Image
                      src="./images/inforlogo.png"
                      border={true}
                      zoom={true}
                      className="logo-image"
                    ></Image>
                  </div>
                  <div className="flex-1">
                    <div>
                      <span className="text fw-bold">
                        User Interface Designer
                      </span>
                    </div>
                    <div className="mb-3">
                      <span className="text">
                        Jun 2008 - Jun 2010 · 2 yrs 1 mo
                      </span>
                    </div>
                    <div>
                      <span className="text">Infor (Approva Systems)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
