import { v4 as uuid } from "uuid";

export default [
  {
    id: uuid(),
    prefix: "",
    name: "Temperature Monitoring System",
    title: "Temperature Monitoring System",
    subtitle: "Live and historical temperature monitoring system for reefers",
    description:
      "A reefer temperature monitoring system is a critical tool for businesses that transport temperature-sensitive goods. This system is designed to monitor the live temperature of a reefer container, providing real-time updates on the current temperature and any changes. Additionally, the system stores historical temperature data, allowing for analysis of temperature trends over time. Along with live temperature monitoring, the system can track the live location of the reefer container and provide information on various temperature states. The system also generates alerts if the temperature falls outside of a pre-set range, if the container doors are open for an extended period, or if the container is in a different movement stage. Overall, a reefer temperature monitoring system is a crucial tool for businesses that rely on the safe and secure transport of temperature-sensitive goods.",
    imagesrc: [{ src: "./images/temperaturemonitor.png" }],
    videosrc: "./videos/reefertemperature.mp4",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
    question:
      "How can the monitoring system for Reefer Trailers be improved using IoT technology and better UX Design?",
    answer:
      "The solution can be found by  by exploring the questions of Why, What, and How, and it requires a collaborative and iterative process involving a team.",
  },
  {
    id: uuid(),
    prefix: "",
    name: "Notification Manager",
    title: "Notification Manager",
    subtitle: "Notification Manager",
    description:
      "A notification system is critical to Fleet manager. The system allows to stay up to date with the status of the fleet and ensure that everything is running smoothly.",
    imagesrc: [{ src: "./images/notificationmanager1.png" }],
    videosrc: "./videos/notificationsmanager.mp4",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
  {
    id: uuid(),
    prefix: "",
    name: "Asset Tracking",
    title: "Asset Tracking",
    subtitle: "Live asset tracking view",
    description:
      "An asset tracking system is a powerful tool for organizations to manage their assets effectively. By integrating with Google Maps, the system provides real-time visibility of the assets' location and status, allowing users to see their assets' various states at a glance. Additionally, the system's live alert system ensures that users are immediately notified of any critical events or changes in the asset's status, enabling timely actions to be taken. The system's filters also enable users to customize the view of the assets based on their needs, such as filtering by location, type, or condition, making it easier to manage and maintain assets effectively. Overall, an asset tracking system using Google Maps offers an efficient and user-friendly solution to manage assets and optimize operations.",
    imagesrc: [{ src: "./images/mapview1.png" }],
    videosrc: "./videos/assettrackingvideo.mp4",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
  {
    id: uuid(),
    prefix: "For ",
    name: "Temperature Monitoring System",
    title: "Remote Reefer Temperature Control",
    subtitle:
      "User friendly Reefer Temperature Control system along with scheduling and various other control systems",
    description: "",
    imagesrc: [{ src: "./images/temperaturecontrolpanel.png" }],
    videosrc: "",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
  {
    id: uuid(),
    name: "Productivity Report",
    prefix: "For ",
    title: "Productivity Report",
    subtitle: "A general template to show all kind of reports",
    description: "",
    imagesrc: [{ src: "./images/productivityreport.png" }],
    videosrc: "",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
  {
    id: uuid(),
    name: "Asset Overview",
    prefix: "For ",
    title: "Asset Overview",
    subtitle: "Detail overview about an asset and its weekly utilization",
    description: "",
    imagesrc: [{ src: "./images/assetoverview.png" }],
    videosrc: "",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
  {
    id: uuid(),
    name: "Reefer Analytics",
    prefix: "For ",
    title: "Reefer Analytics",
    subtitle: "A Dash to analyze the reefer data over the period of time",
    description: "",
    imagesrc: [{ src: "./images/reeferanalytics.png" }],
    videosrc: "",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
  {
    id: uuid(),
    name: "Log Tracking System",
    prefix: "For CISCO",
    title: "Log Tracking System",
    subtitle: "An out of the box idea for tracking the call logs",
    description: "",
    imagesrc: [{ src: "./images/tracklog.png" }],
    videosrc: "",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
  {
    id: uuid(),
    name: "Dashboard",
    prefix: "For CISCO",
    title: "Dashboard",
    subtitle: "All in one dashboard",
    description: "",
    imagesrc: [{ src: "./images/dashBoard1.png" }],
    videosrc: "",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
  {
    id: uuid(),
    name: "Business Rule Builder",
    prefix: "For Infor",
    title: "Business Rule Builder",
    subtitle: "An easiest way to create business transaction rules",
    description: "",
    imagesrc: [
      { src: "./images/ruleBuilder1.jpg" },
      { src: "./images/ruleBuilder2.jpg" },
      { src: "./images/ruleBuilder3.jpg" },
      { src: "./images/ruleBuilder4.jpg" },
    ],
    videosrc: "",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
  {
    id: uuid(),
    name: "ORG by period",
    prefix: "For Planful",
    title: "ORG by period",
    subtitle:
      "A simplified way to view the organizations based on there utilization.",
    description: "",
    imagesrc: [{ src: "./images/orgbyperiod1.png" }],
    videosrc: "",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
  {
    id: uuid(),
    name: "Excel Ribbon",
    prefix: "For Planful",
    title: "Excel Ribbon",
    subtitle: " Desktop version of online software.",
    description: "",
    imagesrc: [
      { src: "./images/excelRibbon1.jpg" },
      { src: "./images/excelRibbon2.jpg" },
      { src: "./images/excelRibbon3.png" },
    ],
    videosrc: "",
    alternates: "",
    attributes: "",
    color: "",
    selected: false,
  },
  
];
