import React from "react";
import { useEffect, useState, useRef } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  NavLink,
  useNavigate,
} from "react-router-dom";
import { Modal, Accordion, Offcanvas, Button } from "react-bootstrap";
import Image from "../pages/image";

export default function Companies(props) {
  const { show, handleClose } = props;

  return (
    <>
      <Modal show={show} onHide={handleClose} scrollable={true} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Knowledge</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ minHeight: "75vh", maxHeight: "65vh", overflowY: "auto" }}
        >
          <div className="container">
            <div className="mb-3">
              <h3>Certificates</h3>
              <div>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                  <div className="col text-center p-2">
                    <div className="image-parent-div p-3">
                      <Image
                        src={"./images/certificate3.jpg"}
                        border={true}
                        zoom={true}
                      ></Image>
                      <div>
                        <h5>UX Management</h5>
                      </div>
                      <div>
                        <p className="small">Final score: (84%)</p>
                      </div>
                      <div className="m-3">
                        <a
                          class="btn btn-dark"
                          href="https://www.interaction-design.org/members/syed-sohil-anjum/certificate/course/d5403ef7-3f20-4a44-91c3-b70910c55d49"
                          role="button"
                          target="_blank"
                        >
                          View Certificate
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col text-center p-2">
                    <div className="image-parent-div p-1">
                      <Image
                        src={"./images/certificate1.jpg"}
                        border={true}
                        zoom={true}
                      ></Image>
                      <div>
                        <h5>Mobile UX Design</h5>
                      </div>
                      <div>
                        <p className="small">Final score: (88%)</p>
                      </div>
                      <div className="m-3">
                        <a
                          class="btn btn-dark"
                          href="https://www.interaction-design.org/members/syed-sohil-anjum/certificate/course/48b9eaf9-a84e-4cd1-9e69-de1f555b7608"
                          role="button"
                          target="_blank"
                        >
                          View Certificate
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col text-center p-2">
                    <div className="image-parent-div p-3">
                      <Image
                        src={"./images/certificate2.jpg"}
                        border={true}
                        zoom={true}
                      ></Image>
                      <div>
                        <h5>Design Thinking</h5>
                      </div>
                      <div>
                        <p className="small">Final score: (91%)</p>
                      </div>
                      <div className="m-3">
                        <a
                          class="btn btn-dark"
                          href="https://www.interaction-design.org/members/syed-sohil-anjum/certificate/course/0a0eebe5-d2e8-486f-867a-96f1f82796a8"
                          role="button"
                          target="_blank"
                        >
                          View Certificate
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p>
                <span className="fs-3">
                  "Designed 5 fully functional software applications from the
                  ground up."
                </span>
                <br />
                <br />
                My overall knowledge experience is more than 12 years in
                software development, such as gathering client requirements,
                designing end-to-end functionalities, defining UI/UX guidelines,
                and delivering ready-to-use prototypes for development.
              </p>
              <p>
                My goal is to implement solutions that are not only data-driven,
                but also insight-driven, with a focus on achieving perfection. I
                aspire to infuse software with "intelligence by experience" to
                create solutions that truly meet the needs and expectations of
                users.
              </p>
              <p>
                Being proactive by nature, I have successfully designed numerous
                SaaS UIs with minimal requirement documents. In most cases, my
                guidance is based on rough email or verbal communication, from
                which I can design the entire UI/UX functionality.
              </p>
            </div>

            <div>
              <Image
                src="./images/knowledge.png"
                border={true}
                zoom={true}
              ></Image>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
