import { useState, useCallback, useEffect } from "react";
import styles from "../css/image.module.css";

const Image = ({ zoom, ...rest }) => {
  const [click, setClick] = useState(false);

  const setFlag = () => {
    setClick(true);
  };

  const unsetFlag = () => {
    setClick(false);
  };

  if (!zoom) return <img {...rest} />;
  else
    return (
      <>
        {click ? (
          <div
            onClick={unsetFlag}
            className={`${styles.lightbox} ${styles.show} relative`}
          >
            <img
              {...rest}
              className={`${styles.show_image}`}
              style={{ maxWidth: "100%", cursor: "zoom-out" }}
            ></img>
          </div>
        ) : (
          <img
            {...rest}
            onClick={setFlag}
            style={{
              maxWidth: "100%",
              cursor: "zoom-in",
              maxHeight: "600px",
            }}
          ></img>
        )}
      </>
    );
};

export default Image;
