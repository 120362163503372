import React from "react";
import { useEffect, useState, useRef } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  NavLink,
  useNavigate,
} from "react-router-dom";
import { Modal, Accordion, Offcanvas, Button } from "react-bootstrap";
import Image from "../pages/image";

export default function Companies(props) {
  const { show, handleClose } = props;

  return (
    <>
      <Modal show={show} onHide={handleClose} scrollable={true} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>User Experience Design Process</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ minHeight: "75vh", maxHeight: "65vh", overflowY: "auto" }}
        >
          <div className="container">
            <div className="mb-4">
              <Image
                src="./images/uxprocess1.jpg"
                border={true}
                zoom={true}
              ></Image>
            </div>
            <div className="mb-4">
              <Image
                src="./images/uxprocess2.jpg"
                border={true}
                zoom={true}
              ></Image>
            </div>
            <div className="mb-4">
              <Image
                src="./images/uxprocess3.jpg"
                border={true}
                zoom={true}
              ></Image>
            </div>
            <div className="mb-4">
              <Image
                src="./images/uxprocess4.jpg"
                border={true}
                zoom={true}
              ></Image>
            </div>
            <div className="mb-4">
              <Image
                src="./images/uxprocess5.jpg"
                border={true}
                zoom={true}
              ></Image>
            </div>
            <div className="mb-4">
              <Image
                src="./images/uxprocess6.jpg"
                border={true}
                zoom={true}
              ></Image>
            </div>
            <div className="mb-4">
              <Image
                src="./images/uxprocess7.jpg"
                border={true}
                zoom={true}
              ></Image>
            </div>
            <div className="mb-4">
              <Image
                src="./images/uxprocess8.jpg"
                border={true}
                zoom={true}
              ></Image>
            </div>
            <div className="mb-4">
              <Image
                src="./images/uxprocess9.jpg"
                border={true}
                zoom={true}
              ></Image>
            </div>
            <div className="mb-4">
              <Image
                src="./images/uxprocess10.jpg"
                border={true}
                zoom={true}
              ></Image>
            </div>
            <div className="mb-4">
              <Image
                src="./images/uxprocess11.jpg"
                border={true}
                zoom={true}
              ></Image>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
